import { useState } from "react";
import { Link } from "react-router-dom";
import Icon from "../components/Icon";
import Overlay from "./Overlay";
import "../styles/components/footer.sass";

interface TeamMember {
  fullName: string;
  githubUsername: string;
  githubUrl: string;
  avatarUrl: string;
}

function TeamMember(props: { member: TeamMember }) {
  const { avatarUrl, fullName, githubUsername, githubUrl } = props.member;

  return (
    <div className="development-team-member-card">
      <img src={avatarUrl} className="development-team-member-avatar" />
      <p className="development-team-member-full-name">{fullName}</p>
      <p className="development-team-member-social-entry">
        <Icon name="github" size="1.25rem" fill="#fff" />
        <a href={githubUrl}>{githubUsername}</a>
      </p>
    </div>
  );
}

const teamMembers: TeamMember[] = [
  {
    fullName: "Nuutti Varvikko",
    githubUsername: "sneikki",
    githubUrl: "https://github.com/sneikki",
    avatarUrl: "https://avatars.githubusercontent.com/u/60470375?v=4"
  },
  {
    fullName: "Juho Sirén",
    githubUsername: "JVS23",
    githubUrl: "https://github.com/JVS23",
    avatarUrl: "https://avatars.githubusercontent.com/u/74061917?v=4"
  }
];

interface Props {
  isOverlayOpen?: boolean;
  openOverlay: () => void;
  closeOverlay: () => void;
}

function Footer(props: Props): JSX.Element {


  return (
    <footer className="section section--small footer">
      {props.isOverlayOpen && (
        <Overlay
          title="Contact"
          close={props.closeOverlay}
          isOpen={props.isOverlayOpen}
          isPadded={false}
        >
          <p className="contact-overlay-subtitle">Support email</p>
          <p className="contact-overlay-email">support@glomfig.com</p>
          <p className="contact-overlay-subtitle">Development team</p>
          <div className="contact-overlay-devteam">
            {teamMembers.map((member) => (
              <TeamMember key={member.githubUsername} member={member} />
            ))}
          </div>
        </Overlay>
      )}
      <div className="container container-xl footer-container">
        <div className="footer-section footer-section-primary">
          <ul className="footer-links">
            <li className="footer-link">
              <Link to="/privacy">Privacy policy</Link>
            </li>
            <li className="footer-link">
              <p onClick={props.openOverlay}>Contact</p>
            </li>
          </ul>
        </div>

        <div className="footer-section footer-section-secondary">
          <Link to="/" className="header-logo footer-logo">
            Glomfig<span className="color-accent">.com</span>
          </Link>
          <div className="footer-copyright-declaration">© 2023-2025 Glomfigli</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
