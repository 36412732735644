import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { getAvatarUrl, createConfigDescriptors, getTimeApproximation } from "../utils";
import { fetchUser } from "../services/UserService";
import { fetchConfigs } from "../services/ConfigService";
import useTitle from "../hooks/useTitle";
import Box from "../components/Box";
import ConfigDescriptor from "../components/ConfigDescriptor";
import Button from "../components/io/Button";
import "../styles/views/home.sass";

function drawConfigDescriptors(configDescriptors: ConfigDescriptor[] | null) {
  if (Array.isArray(configDescriptors)) {
    if (configDescriptors.length > 0) {
      return configDescriptors.map((descriptor) => (
        <ConfigDescriptor key={descriptor.id} descriptor={descriptor} />
      ))
    } else {
      return (
        <div className="config-list-not-found configs-not-found">
          <p>No configs were found</p>
        </div>
      )
    }
  } else {
    return (
      <div className="config-list-loading recent-configs-loading">
        <p>Loading recent configs</p>

        <div className="configs-loading-icon">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    )
  }
}

function Home(): JSX.Element {
  useTitle();

  const [configDescriptors, setConfigDescriptors] = useState<
    ConfigDescriptor[] | null
  >(null);

  useEffect(() => {
    loadRecentConfigs();
  }, []);

  async function loadRecentConfigs() {
    try {
      setConfigDescriptors(
        await Promise.all(
          createConfigDescriptors(
            await fetchConfigs(4, "desc")
          )
        ) as ConfigDescriptor[]
      );
    } catch {
      return;
    }
  }

  return (
    <div className="home-content-field">
      <div className="home">
        <section className="section home-section">
          <div className="jumbotron-body">
            <div className="home container container-xl display-flex">
              <div className="home-panel home-panel-primary">
                <div className="home-title">
                  <p>
                    Glom
                    <wbr />
                    <span className="color-accent">fig</span>
                  </p>
                </div>
                <p className="home-title-subtext">
                  The #1 CS:GO config&shy;uration mana&shy;ger
                </p>
                <p className="home-description">
                  Glomfig is a community-driven tool for creating and sharing
                  configurations for CS:GO. This tool can be used to manage your
                  own configurations between accounts, or to share them with
                  your friends. Join now to make the most out of your gaming
                  experience!
                </p>

                <Button.Group grow={true}>
                  <Link
                    to="/register"
                    className="button button--regular button--large"
                  >
                    Join now
                  </Link>
                  <Link to="/explore" className="button button--blank button--large">
                    Explore
                  </Link>
                </Button.Group>
              </div>

              <div className="home-panel home-panel-secondary">
                <Box title="Recent configurations" isPadded={false}>
                  {drawConfigDescriptors(configDescriptors)}
                </Box>
              </div>
            </div>

            {/* <div className="container"> */}
            {/*   <Formik */}
            {/*     initialValues={{ */}
            {/*       searchTerm: "" */}
            {/*     }} */}
            {/*     onSubmit={handleSearch} */}
            {/*   > */}
            {/*     <Form className="home-search"> */}
            {/*       <label htmlFor="search-term" className="home-search-label">Search term</label> */}

            {/*       <div className="home-search-bar"> */}
            {/*         <Field */}
            {/*           name="searchTerm" */}
            {/*           type="text" */}
            {/*           placeholder="Search for configs, users etc." */}
            {/*           className="home-search-bar-field" */}
            {/*         /> */}
            {/*         <input type="submit" value="Search" className="home-search-bar-button" /> */}
            {/*       </div> */}
            {/*     </Form> */}
            {/*   </Formik> */}
            {/* </div> */}
          </div>
        </section>
      </div>
      <section className="section section section-quick-start">
        <div className="container container-vertical container-xl display-flex">
          <p className="home-secondary-title">Quick start</p>
          <div className="home-info-container">
            <Box title="Create" className="home-info-box">
              <p className="home-info-box-text">Create an account</p>
              <p className="home-info-box-text">Log in to your account</p>
              <p className="home-info-box-text">
                Navigate to the config creation
              </p>
            </Box>

            <Box title="Edit" className="home-info-box">
              <p className="home-info-box-text">Choose the game</p>
              <p className="home-info-box-text">
                Give the config a name and description
              </p>
              <p className="home-info-box-text">Create or upload a resource</p>
            </Box>

            <Box title="Use" className="home-info-box">
              <p className="home-info-box-text">
                Edit existing resources or upload your existing config files
              </p>
              <p className="home-info-box-text">
                Save the resource and retrieve it anytime and anywhere you want
              </p>
            </Box>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
